import Vue from "vue";
import VueRouter from "vue-router";
//import TP from "../components/TempPage.vue";

import Dnd from "dnd";
import Mutations from "mutations";
import Grid from "grid";
import Dna from "dna";
import Vinjett from "vinjett";
import Gene from "gene-tech";
import Tree from "evotree";
import TreeBuild from "evo-build-vite";

Vue.use(VueRouter);

const routes = [
  {
    path: "/vinjett1",
    name: "Vinjett nivå 1",
    component: Vinjett.DifficultyLevel1
  },
  {
    path: "/vinjett2",
    name: "Vinjett nivå 2",
    component: Vinjett.DifficultyLevel2
  },
  {
    path: "/vinjett3",
    name: "Vinjett nivå 3",
    component: Vinjett.DifficultyLevel3
  },
  {
    path: "/life1",
    name: "Life nivå 1",
    component: Grid.Life1
  },
  {
    path: "/life2",
    name: "Life nivå 2",
    component: Grid.Life2
  },
  {
    path: "/life3",
    name: "Life nivå 3",
    component: Grid.Life3
  },
  {
    path: "/dnd1",
    name: "Dnd nivå 1",
    component: Dnd.Dnd1
  },
  {
    path: "/dnd2",
    name: "Dnd nivå 2",
    component: Dnd.Dnd2
  },
  {
    path: "/dnd3",
    name: "Dnd nivå 3",
    component: Dnd.Dnd3
  },
  {
    path: "/cell1",
    name: "Cell nivå 1",
    component: Grid.Cells
  },
  {
    path: "/cell2",
    name: "Cell nivå 2",
    component: Grid.Cells
  },
  {
    path: "/cell3",
    name: "Cell nivå 3",
    component: Grid.Cells
  },
  {
    path: "/cellcore",
    name: "Cellcore",
    component: Dna.Cellcore
  },
  {
    path: "/replikation",
    name: "Replikation",
    component: Dna.Replikation
  },
  {
    path: "/mitosmeios",
    name: "Mitosmeios",
    component: Dna.Mitosmeios
  },
  {
    path: "/transkription",
    name: "Transkription",
    component: Dna.Transkription
  },
  {
    path: "/translation",
    name: "Translation",
    component: Dna.Translation
  },
  {
    path: "/proteins1",
    name: "Proteins nivå 1",
    component: Dna.Proteins
  },
  {
    path: "/proteins2",
    name: "Proteins nivå 2",
    component: Dna.Proteins
  },
  {
    path: "/proteins3",
    name: "Proteins nivå 3",
    component: Dna.Proteins
  },
  {
    path: "/mutations_sim1",
    name: "Mutations sim1",
    component: Mutations.Sim1
  },
  {
    path: "/mutations_sim2",
    name: "Mutations sim2",
    component: Mutations.Sim2
  },
  {
    path: "/mutations_sim3",
    name: "Mutations sim3",
    component: Mutations.Sim3
  },
  {
    path: "/mutations_sim4",
    name: "Mutations sim4",
    component: Mutations.Sim4
  },
  {
    path: "/tree1",
    name: "Tree1",
    component: Tree.RelativeTree //Tree.Tree
  },
  {
    path: "/tree2",
    name: "Tree2",
    component: Tree.TimeTree
  },
  {
    path: "/tree3d1",
    name: "Tree3d1",
    component: TreeBuild.Level1Task1
  },
  {
    path: "/tree3d2",
    name: "Tree3d2",
    component: TreeBuild.Level2Task1
  },
  {
    path: "/tree3d3",
    name: "Tree3d3",
    component: TreeBuild.Level3Task1
  },
  {
    path: "/gene1d1",
    name: "Gene1d1",
    component: Gene.Gene1d1
  },
  {
    path: "/gene1d2",
    name: "Gene1d2",
    component: Gene.Gene1d2
  },
  {
    path: "/gene1d3",
    name: "Gene1d3",
    component: Gene.Gene1d3
  },
  {
    path: "/gene2",
    name: "Gene2",
    component: Gene.Gene2
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
