// API for NTA Digital Portal messages

const sendMessage = (message, window) => {
  window.top.postMessage(message, "*");
};

const handleMessage = (e, router) => {
  var key = e.message ? "message" : "data";
  var data = e[key];
  var message;

  if (process.env.NODE_ENV === "development") {
    console.log("Message", message);
  }

  if (typeof data === "string") {
    // Old API
    message = data; // Probably Webpack object
  } else if ("message" in data) {
    // New API
    message = data.message;
    // Save other things in the data object if needed
  }

  /* new stuff might need???
  if (message === 'task-1') {
    // run code for task 1
    // ...
  }
  
  if (data === 'task-2) {
    // run code for task 2
    // ...
    // When complete show hint
    showTaskHint();
  }
  */

  router.push(message).catch(() => {
    /* Invalid or current route. */
  });
  console.log(router);
};

const initHandleMessages = (router, window) => {
  window.addEventListener("message", e => {
    handleMessage(e, router);
  });
};

module.exports = {
  sendMessage,
  initHandleMessages
};
