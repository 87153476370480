<template>
  <div class="egg">
    <div v-if="env == 'development'" id="nav">
      |
      <router-link to="/vinjett1">Vinjett1</router-link> |
      <router-link to="/life1">Life1</router-link> |
      <router-link to="/dnd1">Dnd1</router-link> |
      <router-link to="/cell1">Cell1</router-link> |
      <br />
      | <router-link to="/cellcore">Cell Core</router-link> |
      <router-link to="/replikation">Replikation</router-link> |
      <router-link to="/mitosmeios">Mitos Meios</router-link> |
      <router-link to="/transkription">Transkription</router-link> |
      <router-link to="/translation">Translation</router-link> |
      <router-link to="/proteins1">Proteiner</router-link> |
      <br />
      | <router-link to="/mutations_sim1">Sim1</router-link> |
      <router-link to="/mutations_sim2">Sim2</router-link> |
      <router-link to="/mutations_sim3">Sim3</router-link> |
      <router-link to="/mutations_sim4">Sim4</router-link> |
      <br />
      | <router-link to="/tree1">Tree1</router-link> |
      <router-link to="/tree2">Tree2</router-link> |
      <router-link to="/tree3d1">Tree3d1</router-link> |
      <router-link to="/tree3d2">Tree3d2</router-link> |
      <router-link to="/tree3d3">Tree3d3</router-link>
      <br />
      | <router-link to="/gene1d1">Gene1d1</router-link> |
      <router-link to="/gene1d2">Gene1d2</router-link> |
      <router-link to="/gene1d3">Gene1d3</router-link> |
      <router-link to="/gene2">Gene2</router-link> |
    </div>
    <router-view />
  </div>
</template>

<script>
import portalMessages from "./lib/portal-messages.js";

export default {
  name: "Router",
  data: () => {
    return {
      env: process.env.NODE_ENV
    };
  },
  mounted: function() {
    if (this.env == "production") {
      portalMessages.initHandleMessages(this.$router, window);
      parent.postMessage("loaded", "*");
    }
  }
};
</script>

<style scoped>
html {
  width: 100vh;
  height: 100vh;
  overflow: hidden;
}
body {
  margin: 0px;
  padding: 0px;
  min-width: 100vh;
  min-height: 100vh;
  display: block;
  overflow: hidden;
}
div.egg {
  /*font-family: Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: black;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  min-height: 100vh;
  min-width: 100vh;
}

#nav {
  position: relative;
  color: #ffffff;
  padding: 30px;
  font-size: 20px;
  z-index: 100;
}

#nav a {
  font-weight: bold;
  color: #ffffff;
}

#nav a:hover {
  font-weight: bold;
  color: #9c0707;
}
#nav a.router-link-exact-active {
  color: #82b983;
}
</style>
