import Vue from "vue";
import DisplayRouting from "./Router.vue";
import router from "./router/index";
import VModal from "vue-js-modal";

Vue.use(VModal);
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(DisplayRouting)
}).$mount("#app");
